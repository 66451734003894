<template>
  <div>
    <b-card title="">
      <b-card-text>Welcome to IMS!</b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
